var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-menu",
    { attrs: { "background-color": "#eef", mode: _vm.mode } },
    [
      _c("el-menu-item", { attrs: { index: "1" } }, [
        _vm._v("Processing Center"),
      ]),
      _c(
        "el-submenu",
        { attrs: { index: "2" } },
        [
          _c("template", { slot: "title" }, [_vm._v("Workspace")]),
          _c("el-menu-item", { attrs: { index: "2-1" } }, [_vm._v("item one")]),
          _c("el-menu-item", { attrs: { index: "2-2" } }, [_vm._v("item two")]),
          _c("el-menu-item", { attrs: { index: "2-3" } }, [
            _vm._v("item three"),
          ]),
        ],
        2
      ),
      _c("el-menu-item", { attrs: { index: "3" } }, [
        _c("a", { attrs: { href: "https://www.ele.me", target: "_blank" } }, [
          _vm._v("Orders"),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }